import HomePage from "src/components/page/home"
import React from "react"

export default () => (
  <HomePage
    title="WATCH MAXIMUM EFFORT CHANNEL ON FUBO"
    subtitle="Classic and original shows, movies and commercials created to lower
    the blood pressure of Ryan Reynolds and viewers like you."
    videoUrl="https://www.youtube.com/embed/4UxjlA2fT5A"
    videoAlt="DISCONTINUED on Maximum Effort Channel"
  />
)
